import { Trans, t } from "@lingui/macro";
import { Grid, Stack, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import styled from "@emotion/styled";
import HeroLanding from "../../../components/HeroLanding";
import SecondaryButton from "../../../components/SecondaryButton";
import Seo from "../../../components/seo";
import Header from "../../../components/Header";
import { theme } from "../../../themeV2";
import ProductModal from "../../../components/ProductModal";
import DefaultSection from "../../../components/landings/DefaultSection";
import CardColor from "../../../components/CardColor";
import Container from "../../../components/Container";
import LandingsTabs from "../../../components/landings/LandingsTabs";
import ImageComponent from "../../../components/landings/ImageComponent";
import sec1Tab1 from "../../../images/solutions/digital-wealth/wealth-solutions/sec-1_tab-1.png";
import sec1Tab2 from "../../../images/solutions/digital-wealth/wealth-solutions/sec-1_tab-2.png";
import sec1Tab3 from "../../../images/solutions/digital-wealth/wealth-solutions/sec-1_tab-3.png";
import sec1Tab4 from "../../../images/solutions/digital-wealth/wealth-solutions/sec-1_tab-4.png";
import sec1Tab5 from "../../../images/solutions/digital-wealth/wealth-solutions/sec-1_tab-5.png";
import sec1Icon1 from "../../../images/solutions/digital-wealth/wealth-solutions/sec-1_icon-1.png";
import sec1Icon2 from "../../../images/solutions/digital-wealth/wealth-solutions/sec-1_icon-2.png";
import sec1Icon3 from "../../../images/solutions/digital-wealth/wealth-solutions/sec-1_icon-3.png";
import sec2Slide1 from "../../../images/solutions/digital-wealth/wealth-solutions/sec-2_slide-1.png";
import sec2Slide2 from "../../../images/solutions/digital-wealth/wealth-solutions/sec-2_slide-2.png";
import sec2Slide3 from "../../../images/solutions/digital-wealth/wealth-solutions/sec-2_slide-3.png";
import sec2Slide4 from "../../../images/solutions/digital-wealth/wealth-solutions/sec-2_slide-4.png";
import sec2Slide5 from "../../../images/solutions/digital-wealth/wealth-solutions/sec-2_slide-5.png";
import sec2Slide6 from "../../../images/solutions/digital-wealth/wealth-solutions/sec-2_slide-6.png";
import sec2IconBlue from "../../../images/solutions/digital-wealth/wealth-solutions/sec-2_icon-blue.svg";
import sec2IconGreen from "../../../images/solutions/digital-wealth/wealth-solutions/sec-2_icon-green.svg";
import sec2IconOrange from "../../../images/solutions/digital-wealth/wealth-solutions/sec-2_icon-orange.svg";
import sec2IconPurple from "../../../images/solutions/digital-wealth/wealth-solutions/sec-2_icon-purple.svg";
import sec3Img from "../../../images/solutions/digital-wealth/wealth-solutions/sec-3.png";
import sec3IconBlue from "../../../images/solutions/digital-wealth/wealth-solutions/sec-3_icon-blue.svg";
import sec3IconYellow from "../../../images/solutions/digital-wealth/wealth-solutions/sec-3_icon-yellow.svg";
import sec3IconPurple from "../../../images/solutions/digital-wealth/wealth-solutions/sec-3_icon-purple.svg";
import sec3IconGreen from "../../../images/solutions/digital-wealth/wealth-solutions/sec-3_icon-green.svg";
import sec4Img from "../../../images/solutions/digital-wealth/wealth-solutions/sec-4.png";
import sec4IconPurple from "../../../images/solutions/digital-wealth/wealth-solutions/sec-4_icon-purple.png";
import sec4IconOrange from "../../../images/solutions/digital-wealth/wealth-solutions/sec-4_icon-orange.png";
import sec4IconGreen from "../../../images/solutions/digital-wealth/wealth-solutions/sec-4_icon-green.png";
import sec4IconBlue from "../../../images/solutions/digital-wealth/wealth-solutions/sec-4_icon-blue.png";

const metadata = {
  title: "Wealth Solutions",
  description:
    "Our digital wealth solutions empower everyone—from individual investors to financial institutions—to achieve their goals. We provide cutting-edge tools and services for portfolio management, client engagement, and business growth, helping you navigate the complexities of wealth management with confidence.",
};

const SEC_1_TABS = [
  {
    img: sec1Tab1,
    title: t`Performance Evolution`,
    description: (
      <Typography>
        <Trans>
          Track the historical performance of your portfolios with interactive charts and detailed breakdowns. Analyze returns over various time horizons, benchmark against relevant indices, and gain insights into key performance drivers.
        </Trans>
      </Typography>
    ),
  },
  {
    img: sec1Tab2,
    title: t`Asset Allocation`,
    description: (
      <Typography>
        <Trans>
          Visualize and optimize your portfolio's asset allocation across different asset classes, sectors, and regions.  Easily identify concentrations and rebalance your portfolio to align with your investment strategy and risk tolerance.
        </Trans>
      </Typography>
    ),
  },
  {
    img: sec1Tab3,
    title: t`Performance Contribution`,
    description: (
      <Typography>
        <Trans>
          Understand the sources of your portfolio's returns.  Attribute performance to specific asset classes, individual holdings, or investment decisions, allowing for in-depth analysis and informed strategy adjustments.
        </Trans>
      </Typography>
    ),
  },
  {
    img: sec1Tab4,
    title: t`ESG Analysis `,
    description: (
      <Typography>
        <Trans>
          Evaluate the environmental, social, and governance (ESG) factors within your portfolio with MainStreet Partners unique methodology.  Measure ESG rating of your portfolios, identify potential risks and opportunities, and align investments with your values and sustainability goals.
        </Trans>
      </Typography>
    ),
  },
  {
    img: sec1Tab5,
    title: t`Fund and Portfolio Alerts `,
    description: (
      <Typography>
        <Trans>
          Stay informed with automated alerts on key portfolio events and changes. Receive notifications on significant performance fluctuations, asset allocation drifts, ESG rating changes, and other critical factors, enabling proactive portfolio management.
        </Trans>
      </Typography>
    ),
  },
];

const SEC_1_ITEMS = [
  {
    icon: sec1Icon1,
    title: t`Advisors & Institutions`,
    description: t`Equip the team by enhancing personal relationship and advice with continually updated information by taking advantage of our analytics and reporting tools allowing advisors to tailor insights`,
  },
  {
    icon: sec1Icon2,
    title: t`Investors`,
    description: t`A flexible shelf allows managing efficiently the product palette offered to investors across asset classes and their associated recommendations and views`,
  },
  {
    icon: sec1Icon3,
    title: t`End - Clients`,
    description: t`End-clients will have intuitive tools to manage their portfolios independently or with advisors, empowering them to control their financial future and become better investors through inspiration and education.`,
  },
];

const SEC_2_CARDS_ITEMS = [
  {
    icon: sec2IconBlue,
    color: theme.palette.colors.blueLight.main,
    title: t`Connect Pro ESG`,
    description: t`Deliver tailor-made fund and portfolio reports that showcase your corporate branding and style. Our ESG section combines EET information, ESG ratings, Impact metrics, and PAI.`,
  },
  {
    icon: sec2IconOrange,
    color: theme.palette.colors.orangeLight.main,
    title: t`Connect Pro Regulatory`,
    description: (
      <Trans>
        Comply with MiFID regulation and new requirements with our powerful fund class search tool.
        <br /><br />
        Enrich and automate your Corporate Actions alerts.
      </Trans>
    ),
  },
  {
    icon: sec2IconGreen,
    color: theme.palette.colors.green.main,
    title: t`Connect Pro Reporting`,
    description: t`Customise your reports to your brand and corporate image  and present customers extra-financial and ESG results while ensuring regulatory compliance, transparency.`,
  },
  {
    icon: sec2IconPurple,
    color: theme.palette.colors.purple.main,
    title: t`Connect Pro Investments`,
    description: t`All data and insights can be adjusted to your client portfolio's regulatory limits and documentation`,
  },
];

const SEC_2_SLIDER = [
  {
    image: sec2Slide1,
    title: t`The leading-edge platform for fund and market analysis with the best digital capabilities`,
    content: t`Allfunds Connect gives you access to a digital ecosystem of tools and services built on high-quality data from Allfunds’ unrivalled fund platform.`,
  },
  {
    image: sec2Slide2,
    title: t`Fund & Market Tools`,
    content: t`Access a comprehensive database of over 350,000 mutual funds, ETFs, and stocks with our powerful, top-tier tools, including a screener, watchlist, and product comparison.`,
  },
  {
    image: sec2Slide3,
    title: t`AI-Powered Assistant ANA`,
    content: t`Effortlessly navigate a vast ecosystem of 250,000 funds and millions of data points with ANA’s natural language search, seamless navigation, and precise information extraction capabilities.`,
  },
  {
    image: sec2Slide4,
    title: t`Custom Reports with ESG and Investment Research`,
    content: t`Create tailor-made fund and portfolio reports with your branding. Our ESG service and investment analyst research provide comprehensive insights to support informed decision-making.`,
  },
  {
    image: sec2Slide5,
    title: t`Complete Regulatory Documentation Library`,
    content: t`Access an extensive collection of 1,500,000 documents, including KIIDs, prospectuses, factsheets, and UCI-level documents. Our comprehensive library ensures you have all the regulatory documentation you need at your fingertips for thorough compliance and informed decision-making.`,
  },
  {
    image: sec2Slide6,
    title: t`Direct Communication with Allfunds Messenger`,
    content: t`Facilitate seamless communication between distributors and asset managers with Allfunds Messenger. This tool, integrated within Allfunds Connect, offers an intuitive user experience, ensuring efficient and effective interactions`,
  },
];

const SEC_3_CARDS_ITEMS = [
  {
    icon: sec3IconYellow,
    color: theme.palette.colors.yellow.main,
    title: t`Fast & Reliable Trading`,
    description: t`Offer your clients real-time trade execution across all asset classes. Our robust infrastructure ensures low latency and high availability, even during peak market activity.`,
  },
  {
    icon: sec3IconPurple,
    color: theme.palette.colors.purple.main,
    title: t`Fully Customizable`,
    description: t`Create a unique brokerage experience tailored to your brand. Customize the platform's look and feel, integrate your existing systems, and add your own features`,
  },
  {
    icon: sec3IconBlue,
    color: "#375B8D",
    title: t`Mobile-First Approach`,
    description: t`Reach your clients wherever they are. Our solution is optimized for mobile, with responsive web apps and native app capabilities, ensuring a smooth trading experience on any device.`,
  },
  {
    icon: sec3IconGreen,
    color: theme.palette.colors.green.main,
    title: t`Data-Driven Insights`,
    description: t`Leverage our comprehensive APIs to access real-time market data, build custom analytics, and power your own trading algorithms. Empower your clients with the information they need.`,
  },
];

const SEC_4_ITEMS = [
  {
    icon: sec4IconPurple,
    title: t`Accelerated Integration Process`,
    description: t`Quickly connect to 100+ data vendors, reducing setup time and enabling faster business growth.`,
  },
  {
    icon: sec4IconOrange,
    title: t`One Single Entry Point and Support`,
    description: t`Access all data feeds through one portal with comprehensive support for seamless operations.`,
  },
  {
    icon: sec4IconGreen,
    title: t`Multi-Asset`,
    description: t`Integrate various asset types, from equities to derivatives, for comprehensive market coverage.`,
  },
  {
    icon: sec4IconBlue,
    title: t`Real-Time Connectivity`,
    description: t`Receive up-to-date market data instantly, ensuring timely and informed decision-making.`,
  },
];

function WealthSolutions() {
  const [showModal, setShowModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(SEC_1_TABS[0].title);

  const renderRequestButton = () => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => setShowModal(true)}
        text={<Trans>Request info</Trans>}
      />
    );
  };

  const renderCard = ({ color, icon, title, description, key }) => {
    return (
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          transform: {
            sm:
              (key === 1 || key === 3) && "translateY(55px)",
          },
          textAlign: "left",
        }}
      >
        <CardColor
          mt={0}
          color={color}
          icon={icon}
          title={title}
          description={description}
        />
      </Grid>
    );
  };

  const renderCard2 = ({ color, icon, title, description }) => {
    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={3}
        sx={{ textAlign: "left" }}
      >
        <CardColor
          mt={0}
          color={color}
          icon={icon}
          title={title}
          description={description}
        />
      </Grid>
    );
  };

  const slideContent = ({ ...item }, index) => {
    return (
      <Stack key={index} py={2} px={1}>
        <Stack
          key={index}
          spacing={2}
        >
          <ImageComponent
            src={item.image}
            alt={`Carousel img ${index + 1} | Allfunds`}
            extraStyles={{
              overflow: "hidden",
              borderRadius: "6px",
            }}
            />
          <Typography fontWeight="700">
            {item.title}
          </Typography>
          <Typography mt={1}>
            {item.content}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const selectedItem = SEC_1_TABS.find((item) => item.title === selectedTab);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Seo title={metadata.title} description={metadata.description} />
      <Header />
      <HeroLanding
        title={<Trans>World-Class Banking and Wealth Management</Trans>}
        description={
          <Trans>
            Our digital wealth solutions empower everyone—from individual
            investors to financial institutions—to achieve their goals.
            <br />
            <br />
            We provide cutting-edge tools and services for portfolio management,
            client engagement, and business growth, helping you navigate the
            complexities of wealth management with confidence.
          </Trans>
        }
        handleOnclickInfo={() => setShowModal(true)}
        isVideo={true}
        multimedia="https://app.allfunds.com/docs/cms/hero_b8453f951b.mp4"
        widthImage="100%"
        kind="Wealth Solutions"
        kindColor={theme.palette.colors.green.main}
        grid={{
          columnLeft: { md: 6.5 },
          columnRight: { md: 5.5 },
        }}
      />
      <DarkStack
        component="span"
        backgroundColor={theme.palette.colors.blue.dark}
        borderRadius="48px"
      >
        <DefaultSection
          textAlign="center"
          direction="column"
          containerPy={4}
          pretitleAlign="center"
          pretitle={t`Nextportfolio`}
          pretitleColor={theme.palette.colors.green.main}
          color={theme.palette.colors.white.main}
          title={
            <Trans>
              Seamlessly manage the full lifecycle of wealth management on a single platform.
            </Trans>
          }
          description={
            <>
              <Stack display="flex" alignItems="center">
                <Typography pb={1} width={{md: "70%"}}>
                  <Trans>
                    Build and manage your client and model portfolios with our
                    easy-to-use planning and reporting tools, specifically
                    designed for advisors and asset managers
                  </Trans>
                </Typography>
              </Stack>
              <Stack order={{ xs: 1, md: 0 }} spacing={2}>
                <LandingsTabs
                  value={selectedTab}
                  valueSet={setSelectedTab}
                  tabs={SEC_1_TABS.map((item) => item.title)}
                  tabColor={theme.palette.colors.green.main}
                  tabBgColor={theme.palette.colors.green.light}
                />
                <DefaultSection
                  className="sec-1_tab"
                  textAlign="left"
                  direction="row-reverse"
                  padding="0!important"
                  title={selectedItem.title}
                  titleVariant="h3"
                  pretitle={selectedItem.pretitle}
                  description={selectedItem.description}
                  pretitleColor={theme.palette.colors.green.main}
                  multimedia={selectedItem.img}
                />
                <Stack alignItems="center">{renderRequestButton()}</Stack>
              </Stack>
              <Grid
                container
                spacing={{ xs: 2, sm: 3, md: 4 }}
                ml="-24px!important"
                pb={{ md: 2 }}
                justifyContent="center"
                order={{ xs: 0, md: 1 }}
              >
                {SEC_1_ITEMS.map((item, key) => (
                  <Grid item key={key} md={4} sm={6} xs={12}>
                    <Stack spacing={1} textAlign="left">
                      <img
                        src={item.icon}
                        alt="Wealth Solutions - Allfunds"
                        width="24"
                        height="24"
                        style={{ objectFit: "contain" }}
                      />
                      <Typography
                        fontWeight="700"
                        fontSize={{ xs: "16px", md: "24px" }}
                      >
                        {item.title}
                      </Typography>
                      <Typography>{item.description}</Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </>
          }
        />
      </DarkStack>
      <DefaultSection
        pretitle={t`Allfunds Connect`}
        pretitleColor={theme.palette.colors.green.main}
        title={t`Unleash the Power of Informed Investing `}
        buttons={renderRequestButton()}
        description={
          <>
            <Typography>
              <Trans>
                Allfunds Connect delivers fund and market data, reporting tools,
                data analytics, ESG solutions, and portfolio advisory management
                tools within a single platform.
                <br />
                <br />A set of intuitive, user-friendly services and solutions
                to help you grow your business, strengthen client relationships,
                and simplify your operations.
              </Trans>
            </Typography>
            <Grid container spacing={2} ml="-24px!important" pb={{ sm: 5 }}>
              {SEC_2_CARDS_ITEMS.map((item, key) =>
                renderCard({ ...item, key }),
              )}
            </Grid>
          </>
        }
        isSlider={true}
        sliderColor={theme.palette.colors.green.main}
        sliderSettings={{
          speed: 500,
          dots: true,
          arrows: false,
          autoplay: true,
          infinite: true,
          slidesToShow: 1,
          cssEase: "linear",
          autoplaySpeed: 6000,
        }}
        slidesContent={SEC_2_SLIDER.map((item, index) =>
          slideContent(item, index),
        )}
      />
      <DarkStack
        component="span"
        backgroundColor={theme.palette.colors.blue.dark}
        borderRadius="48px"
        py={{ xs: 2, sm: 4, md: 5 }}
      >
        <DefaultSection
          py={0}
          pt={{ xs: 2, sm: 4, md: 5 }}
          color={theme.palette.colors.white.main}
          pretitle={t`Broker Online`}
          pretitleColor={theme.palette.colors.green.main}
          title={t`Real-time, self-driven personalised trade execution of global assetss over all digital platforms`}
          description={
            <>
              <Typography>
                <Trans>
                  Empower your clients with a cutting-edge online brokerage
                  experience. Allfunds provides the technology and
                  infrastructure to launch a fully customized platform with
                  real-time trading, access to global markets (indices, stocks,
                  FX, and more), and seamless execution across all devices. Our
                  solution is API-driven, giving you complete flexibility and
                  control.
                </Trans>
              </Typography>
            </>
          }
          multimedia={sec3Img}
        />
        <Container component="section" py={{ xs: 2, sm: 4 }}>
          <Grid
            container
            spacing={2}
            ml="-24px!important"
            pb={{ sm: 2, md: 0 }}
          >
            {SEC_3_CARDS_ITEMS.map((item, key) =>
              renderCard2({ ...item, key }),
            )}
          </Grid>
          <Stack alignItems="center" mt={2}>
            {renderRequestButton()}
          </Stack>
        </Container>
      </DarkStack>
      <DefaultSection
        direction="row-reverse"
        pretitle={t`Golden Data Hub`}
        pretitleColor={theme.palette.colors.green.main}
        title={t`Your best place to improve the interaction and automation in one entry point`}
        description={
          <>
            <Typography>
              <Trans>
                Improve the interaction and automation in one entry point and
                unlock business opportunities accessing global market data from
                all major providers.
              </Trans>
              <br />
              <br />
              <Trans>
                A single platform, rationalizing multi-providers and
                multi-asset, covering any data need.
              </Trans>
            </Typography>
            <Grid
              container
              spacing={3}
              ml="-24px!important"
              pb={{ sm: 2, md: 0 }}
              justifyContent="center"
            >
              {SEC_4_ITEMS.map((item, key) => (
                <Grid item key={key} md={6} xs={12}>
                  <Stack spacing={1}>
                    <img
                      src={item.icon}
                      alt="Wealth Solutions - Allfunds"
                      width="48"
                      height="48"
                    />
                    <Typography fontWeight="700">{item.title}</Typography>
                    <Typography>{item.description}</Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </>
        }
        multimedia={sec4Img}
        buttons={renderRequestButton()}
      />
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject="Wealth Solutions"
          product="wealth-solutions"
          hasTemplate={false}
        />
      )}
    </ThemeProvider>
  );
}

const DarkStack = styled(Stack)`
  .sec-1_tab img {
    @media screen and (min-width: 1200px) {
      padding-right: 24px;
    }
   };
  
  p:not(.preTitle) {color: ${theme.palette.colors.white.main};
  
`;

export default WealthSolutions;
